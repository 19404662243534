import { defineStore } from 'pinia'
import { useMainStore } from './main'
import { useCompanyStore } from './company'
import {
  AbsenceOptions,
  COMPANY_DECLARATION_PANEL,
  ClassicCtpDetails,
  ClassicCtpDetailsContribution,
  ClassicCtpDetailsPayload,
  CompanyDeclaration,
  CotisationSummaries,
  DECLARATION_TYPE,
  DoethCtpDetails,
  DoethCtpPayload,
  GeneralReductionCtpDetails,
  GeneralReductionCtpPayload,
  INDIVIDUAL_DECLARATION_PANEL,
  IndividualDeclaration,
  LearningTaxCtpDetails,
  LearningTaxCtpDetailsPayload,
  MonthlyDeclarationSummary,
  STATUS,
  SendingReport,
  EligibleEmployeeContract,
  EligibleSocialOrganization,
  INDIVIDUAL_DECLARATION_PANEL_LABEL,
  RegularizationEmployeeContribution,
  RegularizationEmployeeBase,
  MappingErrorPayload,
  RegularizationEmployeeDgfip,
  Regularization,
  DeclarationDashboard,
  RegularizationGeneralReductionCtpDetails,
  RegularizationGeneralReductionCtpPayload,
  RegularizationClassicCtpDetails,
  RegularizationClassicCtpDetailsPayload,
  UrssafContribution,
  SENDING_STATUS,
} from '@/models/declaration.model'
import { teledsnAPI } from '@/services/teledsn-api'
import FileSaver from 'file-saver'
import { ObjKeyValueString } from '@/models/main.model'
import { Helper } from '@/models/declaration.helper'
import { useContractStore } from './contract'
import { AxiosError } from 'axios'
import { SOCIAL_ORGANIZATIONS } from '@/models/company.model'

type State = {
  options: {
    status: STATUS[]
    natures: DECLARATION_TYPE[]
    extraHours: ObjKeyValueString[]
    otherIncomes: ObjKeyValueString[]
    contributions: ObjKeyValueString[]
    exemptions: ObjKeyValueString[]
    bonus: ObjKeyValueString[]
    compensations: ObjKeyValueString[]
    workingTimeUnits: ObjKeyValueString[]
    absences: AbsenceOptions[]
    bases: ObjKeyValueString[]
    urssafs: UrssafContribution[]
    eligibleEmployeeContracts: EligibleEmployeeContract[]
    eligibleOrganizations: EligibleSocialOrganization[]
    regularizationPanels: ObjKeyValueString[]
  }
  declaration: DeclarationDashboard
  summary: MonthlyDeclarationSummary
  cotisationsSynthesis: CotisationSummaries
  individualDeclaration: IndividualDeclaration
  companyDeclaration: CompanyDeclaration
  regularization: Regularization
  ctpDetails: {
    classic?: ClassicCtpDetails
    regularizationClassic?: RegularizationClassicCtpDetails
    doeth?: DoethCtpDetails
    generalReduction?: GeneralReductionCtpDetails
    regularizationGeneralReduction?: RegularizationGeneralReductionCtpDetails[]
    learningTax?: LearningTaxCtpDetails
    loading: boolean
  }
  employeeDetails: {
    contribution?: RegularizationEmployeeContribution
    bases?: RegularizationEmployeeBase[]
    dgfip?: RegularizationEmployeeDgfip
    loading: boolean
  }
  sendingReports: SendingReport
  loading: boolean
  subLoading: boolean
  paySlip: string
  formSubmitted: boolean
}

export const useDeclarationStore = defineStore('declarationStore', {
  state: (): State => ({
    options: {
      status: [
        STATUS.IN_ORDER,
        STATUS.ACCEPTED,
        STATUS.BEING_CORRECTED,
        STATUS.ON_GOING,
        STATUS.COMPLETE,
        STATUS.TO_COMPLETE,
        STATUS.TO_CORRECT,
        STATUS.DEADLINE_EXCEEDED,
        STATUS.PENDING_PAYMENT,
        STATUS.BLOCKED,
      ],
      natures: [
        DECLARATION_TYPE.DSN_ARRET_TRAVAIL,
        DECLARATION_TYPE.DSN_REPRISE_SUITE_ARRET_TRAVAIL,
        DECLARATION_TYPE.DSN_FIN_CONTRAT_TRAVAIL_UNIQUE,
      ],
      extraHours: [],
      otherIncomes: [],
      contributions: [],
      exemptions: [],
      bonus: [],
      compensations: [],
      workingTimeUnits: [],
      absences: [],
      bases: [],
      urssafs: [],
      eligibleEmployeeContracts: [],
      eligibleOrganizations: [],
      regularizationPanels: [
        {
          key: INDIVIDUAL_DECLARATION_PANEL.BASES,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.BASES,
        },
        {
          key: INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.WORKING_TIME,
        },
        {
          key: INDIVIDUAL_DECLARATION_PANEL.ABSENCES,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.ABSENCES,
        },
        {
          key: INDIVIDUAL_DECLARATION_PANEL.BONUS,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.BONUS,
        },
        {
          key: INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.COMPENSATIONS,
        },
      ],
    },
    declaration: {
      id: null,
      status: STATUS.ON_GOING,
      sendingStatus: SENDING_STATUS.KO,
      statusLabel: '',
      period: '',
      rawPeriod: '',
      nature: DECLARATION_TYPE.INCONNU,
      exigibility: '',
      exigibilityLabel: '',
      exigibilityOutdated: false,
      editable: false,
      lastSendingDate: '',
      lastSendingId: null,
      canBeReopened: false,
      returnDeadlineDate: null,
    },
    regularization: {
      period: null,
      readonly: false,
      dockets: [],
      panels: [],
    },
    summary: {
      dsnEntreprise: { statut: STATUS.TO_COMPLETE },
      dsnIndividuelles: [],
      regularisations: { periodes: [] },
      statutEnvoi: SENDING_STATUS.KO,
      modifiable: false,
      dateLimiteEnvoi: null,
    },
    cotisationsSynthesis: {
      serviceStatus: [],
      errors: [],
      cotisationsSummary: [],
    },
    individualDeclaration: {
      id: 0,
      period: { monthYear: '', endDate: '', startDate: '' },
      status: STATUS.TO_COMPLETE,
      employee: { id: 0, name: '', firstName: '' },
      contractId: 70098,
      workingTime: {
        paidWorkingTime: 151.67,
        workingUnit: '',
      },
      extraHours: [],
      absences: [],
      salary: {
        uncappedMonthlyGross: 0,
        grossSubjectToUnemploymentInsuranceContributions: 0,
        reconstitutedGrossSalary: 0,
        basicGrossSalary: 0,
      },
      base: {
        pssModified: null,
        fields: [],
      },
      baseComponents: [],
      otherIncomes: [],
      contributions: [],
      exemptions: [],
      bonus: [],
      compensations: [],
      totals: {
        netSocialAmount: 0,
        netTaxIncome: 0,
        netPayableBeforeTax: 0,
        grossIJSSAmount: 0,
        deduction: 0,
        netTaxIncomeApprenticeDetails: {
          annualMinimumWages: 0,
          previousAccumulation: 0,
          netTaxableBulletin: 0,
          currentAccumulation: 0,
          netTaxIncome: 0,
          temporaryNetTaxIncome: 0,
        },
      },
      withHoldingTax: {
        tauxPAS: null,
        tauxPASCurrentMonth: null,
        tauxPASPreviousMonth: null,
        base: 0,
        amount: 0,
        isTauxPASDiffFromPreviousMonth: false,
      },
      payment: {
        paymentDate: null,
        netPayable: 0,
        netPayableAfterTax: 0,
      },
      admin: {
        shouldRecalculateGeneralReduction: true,
      },
      readonly: false,
    },
    companyDeclaration: {
      id: 0,
      period: { monthYear: '', endDate: '', startDate: '' },
      status: STATUS.TO_COMPLETE,
      readonly: false,
      dockets: [],
    },
    ctpDetails: { loading: false },
    employeeDetails: { loading: false },
    sendingReports: { dateReception: '...', nature: DECLARATION_TYPE.INCONNU, periode: '', rapports: [] },
    loading: false,
    subLoading: false,
    paySlip: '',
    formSubmitted: false,
  }),
  getters: {
    countCompanyDeclarationErrors(): number {
      if (!this.companyDeclaration.errorMap) return 0

      let count =
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.AGIRC_ARRCO].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.CIBTP].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.DGFIP].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.TAX_LIABILITY].messages.length +
        this.companyDeclaration.errorMap[COMPANY_DECLARATION_PANEL.URSSAF].messages.length

      if (this.companyDeclaration.hasIndividualDeclarationMoreRecent) count++

      return count
    },
    companyDeclarationPanelHaveError() {
      return (panel: COMPANY_DECLARATION_PANEL) =>
        this.companyDeclaration.errorMap && this.companyDeclaration.errorMap[panel]
          ? this.companyDeclaration.errorMap[panel].messages?.length > 0
          : false
    },
    getCompanyDeclarationPanelErrors() {
      return (panel: COMPANY_DECLARATION_PANEL) => {
        if (panel.toString().includes(COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE)) {
          panel = COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE
        }
        return this.companyDeclaration.errorMap
          ? this.companyDeclaration.errorMap[panel]
          : { messages: [], fields: [], messagesPerField: new Map<string, string[]>() }
      }
    },
    countIndividualDeclarationErrors(): number {
      if (!this.individualDeclaration.errorMap) return 0

      const count =
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.ABSENCES].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.BONUS].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.BASES].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX].messages.length +
        this.individualDeclaration.errorMap[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messages.length

      return count
    },
    individualDeclarationPanelHaveError() {
      return (panel: INDIVIDUAL_DECLARATION_PANEL) =>
        this.individualDeclaration.errorMap ? this.individualDeclaration.errorMap[panel].messages.length > 0 : false
    },
    getIndividualDeclarationPanelErrors() {
      return (panel: INDIVIDUAL_DECLARATION_PANEL) =>
        this.individualDeclaration.errorMap
          ? this.individualDeclaration.errorMap[panel]
          : { fields: [], messages: [], messagesPerField: new Map<string, string[]>() }
    },
  },
  actions: {
    getSubmissionDetailPDF(lastSendingId: number) {
      const mainStore = useMainStore()
      const companyStore = useCompanyStore()
      try {
        window.open(
          `${import.meta.env.VITE_TELEDSN_BACKEND_URL}/declarations/telecharger/detail-envoi/${
            companyStore.company.id
          }/${lastSendingId}`,
          '_blank',
        )
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur sur le débranchement vers le détail d'envoi",
        })
      }
    },
    getCertificatesPDF(declarationId: number) {
      const mainStore = useMainStore()
      const companyStore = useCompanyStore()
      try {
        window.open(
          `${import.meta.env.VITE_TELEDSN_BACKEND_URL}/declarations/telecharger/certificat/${
            companyStore.company.id
          }/${declarationId}`,
          '_blank',
        )
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({ value: 'Erreur sur le débranchement vers le certficat' })
      }
    },
    async getDsnFile(declarationId: number, nature: DECLARATION_TYPE, period: string) {
      const mainStore = useMainStore()
      try {
        this.loading = true
        const response = await teledsnAPI.getDsnFile(declarationId, nature)
        FileSaver.saveAs(new Blob([response]), 'teledsn-' + period)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({ value: 'Erreur pendant le téléchargement du fichier DSN' })
      } finally {
        this.loading = false
      }
    },
    async getReportSendingDeclaration() {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.getReportSendingDeclaration(this.declaration.id!, this.declaration.nature)
        this.sendingReports = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur lors de la récupération du rapport d'envoi",
        })
        this.sendingReports = { dateReception: '...', nature: DECLARATION_TYPE.INCONNU, periode: '', rapports: [] }
      }
    },
    getIndividualDeclarationPDF(individualDeclarationId?: number, employeeId?: number) {
      const mainStore = useMainStore()
      const companyStore = useCompanyStore()
      try {
        window.open(
          `${import.meta.env.VITE_TELEDSN_BACKEND_URL}/declarations/telecharger/detail-dsn-individuelle/${
            companyStore.company.id
          }/${employeeId ?? this.individualDeclaration.employee.id}/${
            individualDeclarationId ?? this.individualDeclaration.id
          }`,
          '_blank',
        )
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({ value: 'Erreur sur le débranchement vers le pdf' })
      }
    },
    async getPaySlip(individualDeclarationId: number) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getPaySlip(individualDeclarationId)
        return response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({ value: "Erreur pendant l'affichage du bulletin de paie" })
      }
    },
    async getMonthlyDeclarationSummary() {
      this.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getMonthlyDeclarationSummary(this.declaration.id!)
        this.summary = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({ value: 'Erreur pendant la récupération du résumé' })
      } finally {
        this.loading = false
      }
    },
    async getExtraHourOptions(contractId?: number | string, monthYear?: string) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getExtraHourOptions(
          contractId || this.individualDeclaration.contractId,
          monthYear || this.individualDeclaration.period.monthYear,
        )
        this.options.extraHours = response

        return this.options.extraHours
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des heures supplementaires',
        })
      }
    },
    async getOtherIncomeOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getOtherIncomeOptions(
          this.individualDeclaration.contractId,
          this.individualDeclaration.period.monthYear,
        )
        this.options.otherIncomes = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des autres revenus',
        })
      }
    },
    async getCotisationsSummary() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getCotisationsSummary(this.declaration.id!)
        this.cotisationsSynthesis.cotisationsSummary = response.syntheses
        this.cotisationsSynthesis.errors = response.messagesErreursEnvois
        this.cotisationsSynthesis.serviceStatus = response.desactivationEnvois
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du résumé des cotisations',
        })
      }
    },
    async getContributionOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getContributionOptions(
          this.individualDeclaration.contractId,
          this.individualDeclaration.period.monthYear,
        )
        this.options.contributions = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des cotisations',
        })
      }
    },
    async getExemptionOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getExemptionOptions(
          this.individualDeclaration.contractId,
          this.individualDeclaration.period.monthYear,
        )
        this.options.exemptions = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des exonérations',
        })
      }
    },
    async getBonusOptions(contractId?: number | string, monthYear?: string) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getBonusOptions(
          contractId || this.individualDeclaration.contractId,
          monthYear || this.individualDeclaration.period.monthYear,
        )
        this.options.bonus = response

        return this.options.bonus
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des primes',
        })
      }
    },
    async getCompensationOptions(contractId?: number | string, monthYear?: string) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getCompensationOptions(
          contractId || this.individualDeclaration.contractId,
          monthYear || this.individualDeclaration.period.monthYear,
        )
        this.options.compensations = response

        return this.options.compensations
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des indemnités',
        })
      }
    },
    async getWorkingTimeUnitOptions(contractId?: number | string, monthYear?: string) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getWorkingTimeUnitOptions(
          contractId || this.individualDeclaration.contractId,
          monthYear || this.individualDeclaration.period.monthYear,
        )
        this.options.workingTimeUnits = response

        return this.options.workingTimeUnits
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des unités de temps',
        })
      }
    },
    async getAbsenceOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getAbsenceOptions(
          this.individualDeclaration.contractId,
          this.individualDeclaration.period.monthYear,
        )
        this.options.absences = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des absences',
        })
      }
    },
    async getIndividualDeclaration() {
      const mainStore = useMainStore()
      const contractStore = useContractStore()

      try {
        const response = await teledsnAPI.getIndividualDeclaration(this.individualDeclaration.id)
        this.individualDeclaration = Helper.transformIndividualDeclarationPayload(
          response,
          this.individualDeclaration.id,
        )
        contractStore.contract.id = this.individualDeclaration.contractId
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des informations concernant la déclaration individuelle',
        })
      }
    },
    async deleteDeclaration() {
      const mainStore = useMainStore()
      try {
        await teledsnAPI.deleteDeclaration(this.declaration.id!)
        mainStore.serverResponseStatut.successMessage = 'La DSN a bien été supprimée'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      }
    },
    async getBaseOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getBaseOptions()
        this.options.bases = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la liste des bases',
        })
      }
    },
    async createMonthlyDeclaration(periode: string, companyId: number, force?: boolean) {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.createMonthlyDeclaration({
          periode,
          entrepriseId: companyId,
          dsnSuite: force,
        })
        return response.id
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      }
    },
    async putIndividualDeclaration(persistence: boolean, panel?: INDIVIDUAL_DECLARATION_PANEL) {
      this.subLoading = true
      try {
        const payload = Helper.transformIndividualDeclarationToPayload(this.individualDeclaration)
        const response = await teledsnAPI.putIndividualDeclaration(payload, {
          panel,
          persistence,
          individualDeclarationId: this.individualDeclaration.id,
        })
        this.individualDeclaration = Helper.transformIndividualDeclarationPayload(
          response.detail,
          this.individualDeclaration.id,
        )
      } catch (error) {
        if ((<AxiosError>error).response?.data) {
          this.individualDeclaration.errorMap = Helper.transformIndividualDeclarationErrors(
            (<AxiosError>error).response?.data as Record<INDIVIDUAL_DECLARATION_PANEL, MappingErrorPayload>,
          )
        }
        if (persistence && this.countIndividualDeclarationErrors === 0) return true
      } finally {
        this.subLoading = false
      }
    },
    async getDefaultValueForAContribution(contributionCode: string) {
      const mainStore = useMainStore()

      try {
        const payload = Helper.transformIndividualDeclarationToDefaultValuePayload(this.individualDeclaration)
        const response = await teledsnAPI.getDefaultValueForAContribution(
          this.individualDeclaration.id,
          contributionCode,
          payload,
        )
        return response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du montant par défaut de la contribution',
          blocking: false,
        })
      }
    },
    async getDefaultValueForAExemption(exemptionCode: string) {
      const mainStore = useMainStore()

      try {
        const payload = Helper.transformIndividualDeclarationToDefaultValuePayload(this.individualDeclaration)
        const response = await teledsnAPI.getDefaultValueForAExemption(
          this.individualDeclaration.id,
          exemptionCode,
          payload,
        )
        return response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant la récupération du montant par défaut de l'exonération",
          blocking: false,
        })
      }
    },
    async getCompanyDeclaration() {
      const mainStore = useMainStore()
      this.loading = true
      try {
        const response = await teledsnAPI.getCompanyDeclaration(this.companyDeclaration.id)
        this.companyDeclaration = Helper.transformCompanyDeclarationPayload(response, this.companyDeclaration.id)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des informations concernant la déclaration entreprise',
        })
      } finally {
        this.loading = false
      }
    },
    async saveCompanyDeclaration() {
      this.subLoading = true
      try {
        const payload = Helper.transformCompanyDeclarationToPayload(this.companyDeclaration)
        this.companyDeclaration.errorMap = Helper.transformCompanyDeclarationErrors()
        await teledsnAPI.saveCompanyDeclaration(this.companyDeclaration.id, payload)
      } catch (error) {
        if ((<AxiosError>error).response?.data) {
          this.companyDeclaration.errorMap = Helper.transformCompanyDeclarationErrors(
            (<AxiosError>error).response?.data as Record<COMPANY_DECLARATION_PANEL, MappingErrorPayload>,
          )
        }
        if (this.countCompanyDeclarationErrors === 0) return true
      } finally {
        this.subLoading = false
      }
    },
    async getUrssafContributionOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getUrssafContributionOptions(this.companyDeclaration.id)
        this.options.urssafs = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du référentiel URSSAF',
        })
      }
    },
    async resetIndividualDeclaration() {
      this.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.resetIndividualDeclaration(this.individualDeclaration.id)
        this.individualDeclaration = Helper.transformIndividualDeclarationPayload(
          response.detail,
          this.individualDeclaration.id,
        )
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    async resetCompanyDeclaration() {
      const mainStore = useMainStore()
      this.loading = true
      try {
        const response = await teledsnAPI.resetCompanyDeclaration(this.companyDeclaration.id)
        this.companyDeclaration = Helper.transformCompanyDeclarationPayload(response, this.companyDeclaration.id)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la réinitialisation',
        })
      } finally {
        this.loading = false
      }
    },
    async getDoethCtpDetails(ctp: string, isRegularization?: boolean) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const params = {
          ctpCode: ctp,
          isRegularization,
          period: isRegularization ? this.regularization.period : null,
        }
        const response = await teledsnAPI.getDoethCtpDetails(this.companyDeclaration.id, params)
        this.ctpDetails.doeth = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du détail',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async saveDoethCtp(isRegularization?: boolean) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const params = {
          isRegularization,
          period: isRegularization ? this.regularization.period : null,
        }
        const payload: DoethCtpPayload[] = []
        this.ctpDetails.doeth?.cotisations.forEach((cotisation) => {
          payload.push({
            code: cotisation.code,
            montantCotisation: cotisation.montantCotisation,
            anneeRattachement: this.ctpDetails.doeth?.anneeRattachement ?? 0,
          })
        })
        const response = await teledsnAPI.saveDoethCtp(this.companyDeclaration.id, payload, params)

        //Permet de récuperer la cotisation doeth dans le bordereau concerné
        const urssafDoethCtp = isRegularization
          ? this.regularization.dockets
              .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
              ?.contributions.find(
                (contribution) => contribution.key === response.codeType && contribution.count === response.qualifiant,
              )
          : this.companyDeclaration.dockets
              .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
              ?.contributions.find(
                (contribution) => contribution.key === response.codeType && contribution.count === response.qualifiant,
              )

        if (urssafDoethCtp && urssafDoethCtp.base && urssafDoethCtp.amount) {
          urssafDoethCtp.base.value = response.montantAssiette
          urssafDoethCtp.amount.value = response.montantCotisation
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement",
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async getGeneralReductionCtpDetails(ctp: string) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getGeneralReductionCtpDetails(this.companyDeclaration.id, ctp)
        this.ctpDetails.generalReduction = response
        if (this.ctpDetails.generalReduction.salaries.length > 0) {
          this.ctpDetails.generalReduction.salaries.push({
            nom: 'Total',
            prenom: '',
            idContrat: '-',
            contratDateDebut: '-',
            partUrssaf: this.ctpDetails.generalReduction.montantPartUrssaf,
            partRetraite: this.ctpDetails.generalReduction.montantPartRetraite,
            isReadOnly: true,
            dsnId: 0,
          })
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du détail',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async getRegularizationGeneralReductionCtpDetails(ctp: string) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getRegularizationGeneralReductionCtpDetails(
          this.companyDeclaration.id,
          ctp,
          this.regularization.period!,
        )
        this.ctpDetails.regularizationGeneralReduction = response

        if (this.ctpDetails.regularizationGeneralReduction.length > 0) {
          this.ctpDetails.regularizationGeneralReduction.push({
            cId: 0,
            nomSalarie: '',
            nom: 'Total',
            prenom: '',
            numSecuNumNtt: '',
            montant: 0,
          })
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du détail',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async recalculateGeneralReductionExemption(code: string, amount: number) {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.recalculateGeneralReductionExemption({
          individualDeclarationId: this.individualDeclaration.id,
          code,
          amount,
        })

        if (response.reductionUrssaf != null) {
          const urssafExemption = this.individualDeclaration.exemptions.find(
            (exemption) => exemption.key === Helper.URSSAF_PART,
          )
          if (urssafExemption && urssafExemption.amount) urssafExemption.amount.value = response.reductionUrssaf
        }
        if (response.reductionRetraite != null) {
          const pensionExemption = this.individualDeclaration.exemptions.find(
            (exemption) => exemption.key === Helper.PENSION_PART,
          )
          if (pensionExemption && pensionExemption.amount) pensionExemption.amount.value = response.reductionRetraite
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant le recalcul de la part urssaf / retraite (réduction générale)',
        })
      }
    },
    async recalculateGeneralReductionCtp(code: string, amount: number, individualDeclarationId: number) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.recalculateGeneralReductionCtp(this.companyDeclaration.id, {
          code,
          amount,
          individualDeclarationId,
        })
        return response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant le recalcul',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async saveGeneralReductionCtp(payload: GeneralReductionCtpPayload[]) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.saveGeneralReductionCtp(this.companyDeclaration.id, payload)

        //Permet de récuperer les cotisations liées à la réduction générale dans les 2 bordereaux concernés
        const urssafGeneralReduction = this.companyDeclaration.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
          ?.contributions.find((contribution) => contribution.key === response.urssaf.ctp)
        const retraiteGeneralReduction = this.companyDeclaration.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO)
          ?.contributions.find((contribution) => contribution.key === response.retraite.codeCotisation)

        if (urssafGeneralReduction && urssafGeneralReduction.base && urssafGeneralReduction.amount) {
          urssafGeneralReduction.amount.value = -response.urssaf.assiette // montant = assiette puisque le taux est à 100%
          urssafGeneralReduction.base.value = response.urssaf.assiette
        }
        if (retraiteGeneralReduction && retraiteGeneralReduction.amount && retraiteGeneralReduction.base) {
          retraiteGeneralReduction.amount.value = response.retraite.cotisation
          retraiteGeneralReduction.base.value = response.retraite.assiette
        }

        //Si présence d'un ctpOppose alors il faut également le mettre à jour
        if (response.urssaf.ctpOppose && response.urssaf.ctpOpposeAssiette) {
          const urssafCtpOppose = this.companyDeclaration.dockets
            .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
            ?.contributions.find((contribution) => contribution.key === response.urssaf.ctpOppose)
          if (urssafCtpOppose && urssafCtpOppose.amount && urssafCtpOppose.base) {
            urssafCtpOppose.amount.value = response.urssaf.ctpOpposeAssiette // montant = assiette puisque le taux est à 100%
            urssafCtpOppose.base.value = response.urssaf.ctpOpposeAssiette
          }
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement",
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async saveRegularizationGeneralReductionCtp(ctp: string) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const params = {
          ctpCode: ctp,
          period: this.regularization.period!,
        }

        const payload: RegularizationGeneralReductionCtpPayload[] = this.ctpDetails.regularizationGeneralReduction
          ? this.ctpDetails.regularizationGeneralReduction
              .filter((regularization) => regularization.nom !== 'Total')
              .map((regularization) => {
                return { contratId: regularization.cId, montant: regularization.montant }
              })
          : []

        const response = await teledsnAPI.saveRegularizationGeneralReductionCtp(
          this.companyDeclaration.id,
          payload,
          params,
        )

        //Permet de mettre à jour la valeur de la cotisation liée
        const urssafGeneralReduction = this.regularization.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
          ?.contributions.find((contribution) => contribution.key === response.codeType)

        if (urssafGeneralReduction && urssafGeneralReduction.base && urssafGeneralReduction.amount) {
          urssafGeneralReduction.amount.value = response.montantCotisation
          urssafGeneralReduction.base.value = response.montantAssiette
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement",
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async getLearningTaxCtpDetails(ctp: string, isRegularization?: boolean) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const params = {
          ctpCode: ctp,
          isRegularization,
          period: isRegularization ? this.regularization.period : null,
        }
        const response = await teledsnAPI.getLearningTaxCtpDetails(this.companyDeclaration.id, params)
        this.ctpDetails.learningTax = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du détail',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async saveLearningTaxCtp(isRegularization?: boolean) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const mainContribution = this.ctpDetails.learningTax?.cotisations.find(
          (cotisation) => cotisation.code === this.ctpDetails.learningTax?.codeAssocie,
        )
        const totalBaseForTheYear = this.ctpDetails.learningTax?.cotisations.find(
          (cotisation) => cotisation.code === 'assietteAnnuelle',
        )
        const payload: LearningTaxCtpDetailsPayload = {
          code: this.ctpDetails.learningTax?.codeAssocie ?? '',
          montant: mainContribution?.montant ?? 0,
          anneeRattachement: this.ctpDetails.learningTax?.anneeRattachement ?? 0,
          assiette: totalBaseForTheYear?.montant ?? 0,
        }
        const params = {
          isRegularization,
          period: isRegularization ? this.regularization.period : null,
        }

        const response = await teledsnAPI.saveLearningTaxCtp(this.companyDeclaration.id, payload, params)

        //Permet de récuperer la cotisation liée dans le bordereau concerné
        const urssafLearningTaxCtp = isRegularization
          ? this.regularization.dockets
              .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
              ?.contributions.find(
                (contribution) => contribution.key === response.codeType && contribution.count === response.qualifiant,
              )
          : this.companyDeclaration.dockets
              .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
              ?.contributions.find(
                (contribution) => contribution.key === response.codeType && contribution.count === response.qualifiant,
              )

        if (urssafLearningTaxCtp && urssafLearningTaxCtp.base && urssafLearningTaxCtp.amount) {
          urssafLearningTaxCtp.amount.value = response.montantCotisation
          urssafLearningTaxCtp.base.value = response.montantAssiette
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement",
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async getRegularizationClassicCtpDetails(ctp: string) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const params = {
          ctpCode: ctp,
          period: this.regularization.period!,
        }
        const response = await teledsnAPI.getRegularizationClassicCtpDetails(this.companyDeclaration.id, params)
        this.ctpDetails.regularizationClassic = response
        if (this.ctpDetails.regularizationClassic.cotisationsSalaries.length > 0) {
          this.ctpDetails.regularizationClassic.cotisationsSalaries.push({
            nom: 'Total',
            prenom: '',
            numSecuNumNtt: '',
            cId: 0,
            assiette: this.ctpDetails.regularizationClassic.totalAssiette,
            taux: 0,
            montant: this.ctpDetails.regularizationClassic.totalMontant,
            isReadOnly: true,
          })
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du détail',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async saveRegularizationClassicCtp(ctp: string) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const params = {
          ctpCode: ctp,
          period: this.regularization.period!,
        }
        const payload: RegularizationClassicCtpDetailsPayload[] = this.ctpDetails.regularizationClassic
          ?.cotisationsSalaries
          ? this.ctpDetails.regularizationClassic.cotisationsSalaries
              .filter((regularization) => regularization.nom !== 'Total')
              .map((regularization) => {
                return {
                  contratId: regularization.cId!,
                  montant: regularization.montant,
                  assiette: regularization.assiette,
                  taux: regularization.taux,
                }
              })
          : []
        const response = await teledsnAPI.saveRegularizationClassicCtpDetails(
          this.companyDeclaration.id,
          payload,
          params,
        )

        //Permet de mettre à jour la cotisation lié dans le bordereau URSSAF
        const urssafClassicCtp = this.regularization.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
          ?.contributions.find(
            (contribution) => contribution.key === response.codeType && contribution.count === response.qualifiant,
          )

        if (urssafClassicCtp && urssafClassicCtp.base && urssafClassicCtp.amount) {
          urssafClassicCtp.base.value = response.montantAssiette
          urssafClassicCtp.amount.value = response.montantCotisation
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement",
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async getClassicCtpDetails(ctp: string) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getClassicCtpDetails(this.companyDeclaration.id, ctp)
        this.ctpDetails.classic = response
        if (this.ctpDetails.classic.cotisationsSalaries.length > 0) {
          this.ctpDetails.classic.cotisationsSalaries.push({
            nom: 'Total',
            prenom: '',
            numeroSecu: '',
            idContrat: '-',
            contratDateDebut: '-',
            assiette: this.ctpDetails.classic.totalAssiette,
            taux: 0,
            montant: this.ctpDetails.classic.totalMontant,
            idDsnIndividuelle: 0,
            isReadOnly: true,
          })
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du détail',
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async saveClassicCtp(editedLines: ClassicCtpDetailsContribution[]) {
      this.ctpDetails.loading = true
      const mainStore = useMainStore()

      try {
        const payload: ClassicCtpDetailsPayload = {
          code: this.ctpDetails.classic?.code ?? '',
          codeCotisationAssocie: this.ctpDetails.classic?.codeCotisationAssocie ?? '',
          cotisationsSalaries: editedLines,
        }
        const response = await teledsnAPI.saveClassicCtpDetails(this.companyDeclaration.id, payload)

        //Permet de récuperer les cotisations liées à la réduction générale dans les 2 bordereaux concernées
        const urssafClassicCtp = this.companyDeclaration.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF)
          ?.contributions.find(
            (contribution) => contribution.key === response.codeType && contribution.count === response.qualifiant,
          )

        if (urssafClassicCtp && urssafClassicCtp.base && urssafClassicCtp.amount) {
          urssafClassicCtp.base.value = response.montantAssiette
          urssafClassicCtp.amount.value = response.montantCotisation
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement",
        })
      } finally {
        this.ctpDetails.loading = false
      }
    },
    async getDefaultValueForUrssafContribution(ctp: UrssafContribution, isRegularization: boolean) {
      const mainStore = useMainStore()

      try {
        const regularization = {
          isRegularization,
          period: isRegularization ? this.regularization.period! : '',
        }

        const response = await teledsnAPI.getDefaultValueForUrssafContribution(
          this.companyDeclaration.id,
          {
            code: ctp.key,
            qualifiant: ctp.qualifiant ?? '',
            codeInsee: ctp.codeInsee ?? '',
          },
          regularization,
        )
        return response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération du montant par défaut de la cotisation',
          blocking: false,
        })
      }
    },
    async getRegularizationUrssafContributionOptions() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getRegularizationUrssafContributionOptions(
          this.regularization.period!,
          this.companyDeclaration.id,
        )
        this.options.urssafs = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des options de cotisations URSAFF',
          blocking: false,
        })
      }
    },
    async getRegularizationEligibleContracts() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getRegularizationEligibleContracts(
          this.companyDeclaration.id,
          this.regularization.period!,
        )
        this.options.eligibleEmployeeContracts =
          Helper.transformContratSalarieEligibleToEligibleEmployeeContract(response)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des employées éligibles',
        })
      }
    },
    async getRegularizationEligibleOrganizations() {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getRegularizationEligibleOrganizations(
          this.companyDeclaration.id,
          this.regularization.period!,
        )
        this.options.eligibleOrganizations = Helper.transformOrganismeEligibleToEligibleSocialOrganization(response)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des organismes éligibles',
        })
      }
    },
    async getRegularizationEmployeeContributions(contractId: string) {
      const mainStore = useMainStore()
      this.employeeDetails.loading = true
      try {
        const response = await teledsnAPI.getRegularizationEmployeeContributions(
          this.companyDeclaration.id,
          this.regularization.period!,
          contractId,
        )
        this.employeeDetails.contribution = response

        const totalContributions = Math.round(
          this.employeeDetails.contribution?.cotisationsExonerations.reduce(
            (accumulator, cotisation) =>
              cotisation.montant && cotisation.label !== 'Total' ? accumulator + cotisation.montant : accumulator + 0,
            0,
          ),
        )
        this.employeeDetails.contribution?.cotisationsExonerations.push({
          code: '',
          label: 'Total',
          montant: totalContributions,
          type: 'C',
        })
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des cotisations du salarié',
        })
      } finally {
        this.employeeDetails.loading = false
      }
    },
    async putRegularizationEmployeeContributions(contractId: string) {
      const mainStore = useMainStore()
      this.employeeDetails.loading = true
      try {
        const payload = Helper.constructRegularizationEmployeeContributionPayload(
          contractId,
          this.regularization.period!,
          this.employeeDetails.contribution!,
        )
        const response = await teledsnAPI.putRegularizationEmployeeContributions(this.companyDeclaration.id, payload)
        const agircArrcoDocketByContratId = this.regularization.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO)
          ?.contributions.find((contribution) => contribution.code === response.contratId.toString())
        if (response.regularisation != null && agircArrcoDocketByContratId && agircArrcoDocketByContratId.amount) {
          agircArrcoDocketByContratId.amount.value = response.regularisation
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement des cotisations du salarié",
          blocking: true,
        })
      } finally {
        this.employeeDetails.loading = false
      }
    },
    async getRegularizationEmployeeBases(contractId: string) {
      const mainStore = useMainStore()
      this.employeeDetails.loading = true
      try {
        const response = await teledsnAPI.getRegularizationEmployeeBases(
          this.companyDeclaration.id,
          this.regularization.period!,
          contractId,
        )
        this.employeeDetails.bases = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des bases du salarié',
        })
      } finally {
        this.employeeDetails.loading = false
      }
    },
    async putRegularizationEmployeeBases(contractId: string) {
      const mainStore = useMainStore()
      this.employeeDetails.loading = true
      try {
        const payload = Helper.constructRegularizationEmployeeBasePayload(
          contractId,
          this.regularization.period!,
          this.employeeDetails.bases!,
        )
        await teledsnAPI.putRegularizationEmployeeBases(this.companyDeclaration.id, payload)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement des bases du salarié",
          blocking: true,
        })
      } finally {
        this.employeeDetails.loading = false
      }
    },
    async getRegularizationAbsenceOptions(contractId: string | number) {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.getRegularizationAbsenceOptions(contractId, this.regularization.period!)
        return response.map((option) => {
          return {
            key: option.code,
            value: option.libelle,
            withAmount: option.avecRemuneration,
            withHours: option.avecHeures,
            withDates: option.avecDates,
          }
        })
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant la récupération des options d'absences de régularisation",
        })
      }
    },
    async getRegularizationContractsComplementaryOrganism(organismCode: string, contractId?: string) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getRegularizationContractsComplementaryOrganism(this.companyDeclaration.id, {
          period: this.regularization.period!,
          organismCode,
          contractId,
        })

        return Helper.transformRegularizationContractComplementaryOrganismToObjKeyValueString(response)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des référence contrat du salarié',
        })
      }
    },
    async getRegularizationEmployeeFranceTravail(contractId: string) {
      const mainStore = useMainStore()

      try {
        const response = await teledsnAPI.getRegularizationEmployeeFranceTravail(
          this.companyDeclaration.id,
          this.regularization.period!,
          contractId,
        )

        return response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des cotisations assurance chômage du salarié',
        })
      }
    },
    async getRegularizationEmployeeDgfip(contractId: string) {
      const mainStore = useMainStore()
      this.employeeDetails.loading = true
      try {
        const response = await teledsnAPI.getRegularizationEmployeeDgfip(
          this.companyDeclaration.id,
          this.regularization.period!,
          contractId,
        )

        this.employeeDetails.dgfip = response
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération des éléments DGFIP',
        })
      } finally {
        this.employeeDetails.loading = false
      }
    },
    async putRegularizationEmployeeDgfip(contractId: string) {
      const mainStore = useMainStore()
      this.employeeDetails.loading = true
      try {
        const payload = Helper.constructRegularizationEmployeeDgfipPayload(
          this.employeeDetails.dgfip!,
          contractId,
          this.regularization.period!,
        )

        const response = await teledsnAPI.putRegularizationEmployeeDgfip(this.companyDeclaration.id, payload)

        const dgfipDocketByContratId = this.regularization.dockets
          .find((docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.DGFIP)
          ?.contributions.find((contribution) => contribution.code === response.contratId.toString())
        if (response.montantPas != null && dgfipDocketByContratId && dgfipDocketByContratId.amount) {
          dgfipDocketByContratId.amount.value = response.montantPas
        }
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement des éléments DGFIP",
        })
      } finally {
        this.employeeDetails.loading = false
      }
    },
    async getRegularization() {
      const mainStore = useMainStore()
      this.loading = true
      try {
        await this.getRegularizationEligibleContracts()
        await this.getRegularizationEligibleOrganizations()
        const response = await teledsnAPI.getRegularization(this.companyDeclaration.id, this.regularization.period!)
        this.regularization = Helper.transformRegularizationPayloadToRegularization(
          response,
          this.options.eligibleOrganizations,
          this.options.eligibleEmployeeContracts,
        )
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: 'Erreur pendant la récupération de la régularisation',
        })
      } finally {
        this.loading = false
      }
    },
    async saveRegularization() {
      const mainStore = useMainStore()
      this.subLoading = true
      try {
        const payload = Helper.transformRegularizationToRegularizationPayload(this.regularization)
        await teledsnAPI.putRegularization(this.companyDeclaration.id, payload)
      } catch {
        mainStore.addErrorIfNotAlreadyPresent({
          value: "Erreur pendant l'enregistrement de la régularisation",
        })
        return true
      } finally {
        this.subLoading = false
      }
    },
    async sendDeclaration() {
      const mainStore = useMainStore()
      this.subLoading = true
      try {
        await teledsnAPI.sendDeclaration(this.declaration.id!)
        mainStore.serverResponseStatut.successMessage = 'La déclaration a bien été envoyé'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.subLoading = false
      }
    },
    async deleteRegularization(period: string) {
      const mainStore = useMainStore()
      try {
        await teledsnAPI.deleteRegularization(period, this.companyDeclaration.id)
        mainStore.serverResponseStatut.successMessage = 'La régularisation a bien été supprimée'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      }
    },
    async resetRegularization() {
      const mainStore = useMainStore()
      try {
        const response = await teledsnAPI.resetRegularization(this.regularization.period!, this.companyDeclaration.id)
        this.regularization = Helper.transformRegularizationPayloadToRegularization(
          response,
          this.options.eligibleOrganizations,
          this.options.eligibleEmployeeContracts,
        )
        mainStore.serverResponseStatut.successMessage = 'La régularisation a bien été réinitialisée'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      }
    },
    async reopenDeclaration() {
      const mainStore = useMainStore()
      try {
        this.loading = true
        await teledsnAPI.reopenDeclaration(this.declaration.id!)
        mainStore.serverResponseStatut.successMessage = 'La réouverture de la déclaration est effectif'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
  },
  persist: true,
})
