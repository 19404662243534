<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { router } from '@/routes'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { Router } from '@/models/router.model'
import { useCompanyStore } from '@/stores/company'
import { useEmployeeStore } from '@/stores/employee'
import { useMainStore } from '@/stores/main'
import SelectEmployeeModal from '@/components/contract/SelectEmployeeModal.vue'
import ContactModal from '@/components/commons/ContactModal.vue'
import ConfirmationChangingCompanyModal from '@/components/commons/ConfirmationChangingCompanyModal.vue'
import { Companies } from '@/models/main.model'
import CompaniesDropdown from './CompaniesDropdown.vue'
import ForceSelectionCompanyModal from './ForceSelectionCompanyModal.vue'
import { useVgt } from 'vue-guided-tour'

const route = useRoute()
const mainStore = useMainStore()
const authStore = useAuthStore()
const companyStore = useCompanyStore()
const employeeStore = useEmployeeStore()
const onboardingTour = useVgt()

const showSelectEmployeeModal = ref(false)
const selectedCompany = ref(mainStore.selectedCompany)
const showContactModal = ref(false)
const toogleDarkMode = ref(mainStore.isDarkMode)
const smallMenu = ref()
const previousSelectedCompany = ref(null as Companies | null)
const showConfirmationChangingCompanyModal = ref(false)
const showForceSelectionCompanyModal = ref(false)

const accountMenuOptions = ref([
  {
    label: 'Mes identifiants',
    icon: 'pi pi-user',
    command: () => {
      router.push(Router.MY_ACCOUNT + Router.MY_IDENTIFIERS)
    },
  },
  {
    label: 'Mes abonnements',
    icon: 'pi pi-euro',
    command: () => {
      router.push(Router.MY_ACCOUNT + Router.MY_SUBSCRIPTIONS)
    },
  },
  {
    label: 'Mes factures',
    icon: 'pi pi-file',
    command: () => {
      router.push(Router.MY_ACCOUNT + Router.MY_BILLS)
    },
  },
  {
    label: 'Mes entreprises',
    icon: 'pi pi-building',
    command: () => {
      router.push(Router.MY_ACCOUNT + Router.MY_COMPANIES)
    },
  },
  {
    separator: true,
  },
  {
    label: 'Relancer la présentation de la nouvelle interface',
    icon: 'pi pi-replay',
    command: () => {
      onboardingTour.start(0)
    },
  },
  {
    label: 'Ancienne interface',
    icon: 'pi pi-link',
    command: () => {
      window.location.href = `${import.meta.env.VITE_TELEDSN_BACKEND_URL}/changement-interface?nouvelleInterface=false`
    },
  },
  {
    label: 'Déconnexion',
    icon: 'pi pi-sign-out',
    command: () => {
      authStore.logout()
    },
  },
])
const addMenuOptions = ref([
  {
    label: 'Ajouter une entreprise',
    command: () => {
      mainStore.resetSuggestions()
      const selectedCompany = companyStore.company.id
      companyStore.$reset()
      companyStore.selectedCompanyBeforeCreation = selectedCompany
      router.push(Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS)
    },
  },
  {
    label: 'Ajouter un salarié',
    command: () => {
      mainStore.resetSuggestions()
      employeeStore.$reset()
      router.push(Router.EMPLOYEE)
    },
  },
  {
    label: 'Ajouter un contrat',
    command: () => {
      if (route.path !== '/contrat') employeeStore.employee.id = null
      showSelectEmployeeModal.value = true
    },
  },
])
const smallMenuOptions = computed(() => [
  {
    separator: true,
  },
  { items: [{ label: 'Déclarations', route: Router.DASHBOARD + Router.DECLARATIONS }] },
  {
    label: 'Entreprise',
    items: [
      {
        label: 'Modifier',
        icon: 'pi pi-pencil',
        route: Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS,
      },
      {
        label: 'Gérer les salariés',
        icon: 'pi pi-users',
        route: Router.EMPLOYEES,
      },
      {
        label: 'Ajouter une entreprise',
        icon: 'pi pi-plus',
        command: () => {
          mainStore.resetSuggestions()
          const selectedCompany = companyStore.company.id
          companyStore.$reset()
          companyStore.selectedCompanyBeforeCreation = selectedCompany
          router.push(Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS)
        },
      },
      {
        label: 'Ajouter un salarié',
        icon: 'pi pi-plus',
        route: Router.EMPLOYEE,
      },
      {
        label: 'Ajouter un contrat',
        icon: 'pi pi-plus',
        command: () => {
          if (route.path !== '/contrat') employeeStore.employee.id = null
          showSelectEmployeeModal.value = true
        },
      },
    ],
  },
  {
    label: 'Divers',
    items: [
      {
        label: toogleDarkMode.value ? 'Appliquer le thème clair' : 'Appliquer le thème sombre',
        icon: toogleDarkMode.value ? 'pi pi-sun' : 'pi pi-moon',
        command: () => {
          toogleDarkMode.value = !toogleDarkMode.value
        },
      },
      {
        label: 'Relancer la présentation de la nouvelle interface',
        icon: 'pi pi-replay',
        command: () => {
          onboardingTour.start(0)
        },
      },
      {
        label: "Besoin d'aide",
        icon: 'pi pi-question-circle',
        command: () => {
          showContactModal.value = true
        },
      },
    ],
  },
  {
    label: 'Mon compte',
    items: [
      {
        label: 'Mes identifiants',
        icon: 'pi pi-user',
        route: Router.MY_ACCOUNT + Router.MY_IDENTIFIERS,
      },
      {
        label: 'Mes abonnements',
        icon: 'pi pi-euro',
        route: Router.MY_ACCOUNT + Router.MY_SUBSCRIPTIONS,
      },
      {
        label: 'Mes factures',
        icon: 'pi pi-file',
        route: Router.MY_ACCOUNT + Router.MY_BILLS,
      },
      {
        label: 'Mes entreprises',
        icon: 'pi pi-building',
        route: Router.MY_ACCOUNT + Router.MY_COMPANIES,
      },
      {
        label: 'Déconnexion',
        icon: 'pi pi-sign-out',
        command: () => {
          authStore.logout()
        },
      },
    ],
  },
])
const companyId = computed(() => companyStore.company.id)
const loading = computed(() => mainStore.loading)
const forceSelectionCompany = computed(() => mainStore.forceSelectionCompany)

onMounted(() => {
  if (companyId.value) {
    selectedCompany.value = mainStore.getCompanyById(companyId.value)
    companyStore.getCompanyDetails()
    employeeStore.getCompanyEmployees(companyId.value)
    previousSelectedCompany.value = selectedCompany.value
  } else {
    mainStore.forceSelectionCompany = true
    showForceSelectionCompanyModal.value = true
  }
})

watch(
  () => companyStore.company.id,
  (companyId) => {
    if (companyId) {
      selectedCompany.value = mainStore.getCompanyById(companyId)
      companyStore.getCompanyDetails()
      employeeStore.getCompanyEmployees(companyId)
    } else selectedCompany.value = null
  },
)

watch(selectedCompany, (selectedCompanyValue, oldSelectedCompany) => {
  if (selectedCompanyValue) {
    if (route.meta.mustReturnHomeIfCompanyChanged && selectedCompanyValue !== previousSelectedCompany.value) {
      previousSelectedCompany.value = oldSelectedCompany
      showConfirmationChangingCompanyModal.value = true
    } else {
      companyStore.company.id = selectedCompanyValue.entreprise_id
      mainStore.selectedCompany = selectedCompanyValue
      mainStore.forceSelectionCompany = false
    }
  } else {
    selectedCompany.value = null
    companyStore.company.id = null
    companyStore.selectedCompanyBeforeCreation = null
  }
})

watch(toogleDarkMode, () => {
  mainStore.isDarkMode = toogleDarkMode.value
})

watch(forceSelectionCompany, () => {
  showForceSelectionCompanyModal.value = forceSelectionCompany.value
})

const showSmallMenu = (event: any) => {
  smallMenu.value.toggle(event)
}
</script>

<template>
  <Toolbar class="shadow-5 p-3 border-none fixed w-full z-5 top-0 padding-xxl">
    <template #start>
      <Button
        type="button"
        icon="pi pi-bars"
        @click="showSmallMenu"
        rounded
        aria-haspopup="true"
        aria-controls="small-menu"
        class="flex lg:hidden"
      />
      <router-link to="/">
        <img src="@/assets/png/teledsn_logo.png" class="header-logo hidden lg:flex" alt="Teledsn logo" />
      </router-link>
    </template>
    <template #center>
      <div class="flex md:justify-content-center gap-3">
        <router-link :to="Router.DASHBOARD + Router.DECLARATIONS" v-slot="routerProps" custom>
          <a
            @click="($event) => routerProps.navigate($event)"
            :href="routerProps.href"
            class="p-button p-button-primary p-button-sm no-underline p-button-rounded hidden md:flex onboarding-step-1"
            :class="{ 'p-button-outlined': !router.currentRoute.value.path?.toString().includes(Router.DASHBOARD) }"
            aria-label="Visualiser vos déclarations pour l'entreprise sélectionnée"
            v-tooltip.bottom="{ value: `Visualiser vos déclarations pour l'entreprise sélectionnée`, showDelay: 1000 }"
          >
            <span>Déclarations</span>
          </a>
        </router-link>
        <CompaniesDropdown v-model:selectedCompany="selectedCompany" class="header-company-select onboarding-step-3" />
        <SplitButton
          :model="addMenuOptions"
          size="small"
          severity="secondary"
          rounded
          v-tooltip.bottom="{ value: `Editer/Ajouter les informations de l'entreprise sélectionnée`, showDelay: 1000 }"
          @click="router.push(Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS)"
          class="hidden lg:flex onboarding-step-4"
        >
          <template #icon>
            <i class="p-button-icon-left pi pi-pencil" />
          </template>
          <template #menubuttonicon>
            <i class="pi pi-plus" />
          </template>
        </SplitButton>
        <router-link :to="Router.EMPLOYEES" v-slot="routerProps" custom>
          <a
            @click="($event) => routerProps.navigate($event)"
            :href="routerProps.href"
            class="p-button p-button-icon-only p-button-secondary p-button-sm no-underline p-button-rounded hidden lg:flex onboarding-step-5"
            :class="{ 'p-button-outlined': !router.currentRoute.value.path?.toString().includes(Router.EMPLOYEES) }"
            aria-label="Gérer les salariés de l'entreprise sélectionnée"
            v-tooltip.bottom="{ value: `Gérer les salariés de l'entreprise sélectionnée`, showDelay: 1000 }"
          >
            <i class="pi pi-users" />
          </a>
        </router-link>
      </div>
    </template>
    <template #end>
      <ToggleButton
        v-model="toogleDarkMode"
        class="mr-3 p-tooglebutton-rounded hidden xl:flex"
        onLabel=""
        offLabel=""
        v-tooltip.bottom="{ value: `Changer le thème (clair ou sombre) de l'application`, showDelay: 1000 }"
      >
        <template #icon>
          <i v-if="toogleDarkMode" class="pi pi-moon text-sm" />
          <i v-else class="pi pi-sun text-sm" />
        </template>
      </ToggleButton>
      <Button
        severity="help"
        class="mr-3 hidden xl:flex"
        @click="showContactModal = true"
        icon="pi pi-question-circle"
        v-tooltip.bottom="{ value: `Demande d'assistance au support`, showDelay: 1000 }"
        rounded
        label="Besoin d'aide"
        size="small"
        :outlined="!showContactModal"
      />
      <Button
        severity="help"
        class="mr-3 hidden md:flex xl:hidden"
        @click="showContactModal = true"
        icon="pi pi-question-circle"
        v-tooltip.bottom="{ value: `Demande d'assistance au support`, showDelay: 1000 }"
        rounded
        text
      >
        <template #icon>
          <i class="pi pi-question-circle text-xl" />
        </template>
      </Button>
      <SplitButton
        label="Mon compte"
        :model="accountMenuOptions"
        size="small"
        rounded
        :outlined="!router.currentRoute.value.path?.toString().includes(Router.MY_ACCOUNT)"
        class="hidden xl:flex onboarding-step-6"
        severity="secondary"
        @click="router.push(Router.MY_ACCOUNT + Router.MY_IDENTIFIERS)"
      />
      <Button
        class="mr-3 hidden md:flex xl:hidden onboarding-step-6"
        severity="secondary"
        @click="router.push(Router.MY_ACCOUNT + Router.MY_IDENTIFIERS)"
        icon="pi pi-cog"
        v-tooltip.bottom="{ value: `Accéder aux informations du compte`, showDelay: 1000 }"
        rounded
        text
      >
        <template #icon>
          <i class="pi pi-cog text-xl" />
        </template>
      </Button>
    </template>
  </Toolbar>
  <Menu ref="smallMenu" :model="smallMenuOptions" popup id="small-menu">
    <template #start>
      <div class="flex justify-content-center">
        <img src="@/assets/png/teledsn_logo.png" class="header-logo" alt="Teledsn logo" />
      </div>
    </template>
    <template #submenuheader="{ item }">
      <span class="text-primary font-bold">{{ item.label }}</span>
    </template>
    <template #item="{ item, props }">
      <router-link v-if="item.route" :to="item.route" class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
      </router-link>
      <a v-else class="flex align-items-center" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </template>
  </Menu>
  <SelectEmployeeModal v-if="!loading" v-model:visible="showSelectEmployeeModal" />
  <ContactModal v-if="!loading" v-model:visible="showContactModal" />
  <ForceSelectionCompanyModal
    v-if="!loading"
    :visible="showForceSelectionCompanyModal"
    v-model:selectedCompany="selectedCompany"
  />
  <ConfirmationChangingCompanyModal
    v-if="!loading"
    v-model:visible="showConfirmationChangingCompanyModal"
    v-model:newSelectedCompany="selectedCompany"
    v-model:oldSelectedCompany="previousSelectedCompany"
  />
</template>

<style scoped>
.header-logo {
  height: 50px;
}
</style>
